<template lang="pug">
  span
    div
      v-btn(
        v-if="result && btnResultLabel"
        @click="showResult"
        small
        outlined
        color="primary"
        data-test="results-show"
      ) {{ btnResultLabel }}
    div
      v-tooltip(top max-width="400" :disabled="resultsLoading ? false : true").tooltip
        template(v-slot:activator="{ on }")
          v-btn(
            v-on="on"
            color="primary"
            @click="loadResult"
            small
            data-test="results-get"
            :loading="statusLoaded"
            v-if="canLoadData || statusLoaded || statusFailed"
          ).text-capitalize Get
        span Queued

    v-dialog(v-model="showing" max-width="500" v-if="result" scrollable)
      v-card(data-test="results-info-dialog").exam-info
        v-card-title.headline {{ candidateName }} results:
        v-divider
        v-card-text.exam-info__container
          h3.form-title Main info
          p.text-box
            span.text-box__title Results:
            span.text-box__content {{ result.results }}
          h3.form-title Stats
          template(v-if="result.correct_answers !== null && result.correct_answers !== undefined")
            p.text-box
              span.text-box__title Resultaten:
              span.text-box__content
                template(v-if="result.correct_answers") {{ result.correct_answers }}
                template(v-else) -
          template(v-else-if="result.correct_answers === null || result.correct_answers === undefined")
            p.text-box
              span.text-box__title Onderdeel 1. Gevaarherkenning:
              span.text-box__content
                template(v-if="result.hazard_perception ") {{ result.hazard_perception }}
                template(v-else) -
            p.text-box
              span.text-box__title Onderdeel 2. Kennis:
              span.text-box__content
                template(v-if="result.knowledge") {{ result.knowledge }}
                template(v-else) -
            p.text-box
              span.text-box__title Onderdeel 3. Inzicht:
              span.text-box__content
                template(v-if="result.insight") {{ result.insight }}
                template(v-else) -
          h3.form-title Mistake:
          p.text-box(v-if="result.mistake")
            span.text-box__content(v-html="result.mistake.replace(/\\n/g, '<br />')")
          p.text-box(v-else) -
        v-card-actions
          v-spacer
          v-btn(text color="primary" @click="hideModal" data-test="results-info-dialog-cancel") close
</template>

<script>
import showingMixin from '@/mixins/showing.mixin'
import errorsMixin from '@/mixins/errors.mixin'
import { RESULT_STATUS } from '@/app/admin/modules/results/core/models/resultReservedExam'
import { RESERVED_EXAM_RESULT } from '@/app/admin/modules/course_day/core/courseDay-const'

export default {
  mixins: [showingMixin, errorsMixin],

  inject: ['svc'],

  props: {
    itemID: Number,
    result: Object,

    candidateName: {
      type: String,
      default: 'name'
    },

    isMijnReservation: Boolean
  },

  data: () => ({
    loading: false
  }),

  computed: {
    btnResultLabel() {
      if (!this.result || !this.result.results[0]) return null
      return this.result.results[0].toUpperCase()
    },

    mistake() {
      return null
      if (this.statusInit) return null
      return this.result.mistake.replace(/\n/g, '<br />')
    },

    statusInit() {
      if (!this.result) return true
      return this.canBeLoaded
    },

    canBeLoaded() {
      return this.result === RESERVED_EXAM_RESULT.CAN_BE_LOADED
    },

    canLoadData() {
      if (!this.result || (!this.isMijnReservation && this.result.can_be_loaded)) return true
      return false
    },

    statusLoaded() {
      if (this.result && this.result.status === RESULT_STATUS.FETCHING) return true
      return false
    },

    statusFailed() {
      if (this.result && this.result.status === RESULT_STATUS.FAILED) return true
      return false
    },

    resultsLoading() {
      return false
    }
  },

  methods: {
    async loadResult() {
      try {
        this.loading = true
        await this.svc.loadResult(this.itemID)
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    showResult() {
      this.updateShowing(true)
    },

    hideModal() {
      this.updateShowing(false)
    },
  }
}
</script>

<style lang="scss" scoped>
.text-box {
  margin-top: 0;
  margin-bottom: 5px;

  &__title {
    margin-right: 3px;
  }

  &__content {
    color: #000000;
  }
}

.btn-result {
  display: inline-block;
}

</style>

<style lang="scss">
.exam-info {
  &__container {
    padding-top: 10px !important;
  }

  .form-title {
    padding-top: 10px;

    &:first-child {
      padding-top: 0;
    }
  }
}
</style>
